import React from "react";
import CustomControl from "@/components/Form/CustomControl";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import {
  createBusinessPartnerRoute,
  esodunsSearchRoute,
} from "@/constants/routes";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Skeleton from "@mui/material/Skeleton";
import { DunsSearchType, TEXT, LOOKUP } from "@/constants/forms";
import { Link } from "react-router-dom";
import { Divider, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import AppError from "@/AppError";
import ActionsBar from "@/components/ActionsBar";
import Backdrop from "@/components/Backdrop";
import Alert from "@/components/Form/Alert";
import {
  EDIT,
  canMarketCreateBPKey,
} from "@/constants/constants";
import Loader from "@/components/Loader";
import { AppContext } from "@/context/AppContext";
import { BusinessPartnersContext } from "@/context/BusinessPartnersContext";
import { WARNING } from "@/constants/constants";
import { businessPartnerRoute } from "@/constants/routes";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { ResultsList } from "@/components/ESO/ResultsList";
import { TableHeader } from "@/components/ESO/TableHeader";
import utils from "@/utils/common";
import { handleApiError } from "@/utils/handleApiError";
import { useESOCountryOptionsQuery, useESOFormFieldsQuery } from "@/services/queries";
import { useCreateESOMutation, useSearchESOMutation } from "@/services/mutations";
import { useTranslation } from "react-i18next";
import { StyledHeading, StyledOrBox, StyledPageDescription, StyledPageFab, StyledSecondaryPageFab, StyledSubHeading } from "@/components/Styles/PagesComponentsCommon";
import BPNoResults from "@/components/BusinessPartner/NoResults";
export const EsoSearchPage = () => {
  const navigate = useNavigate();
  const controller = new AbortController();
  const { control, handleSubmit, getValues, setValue } = useForm({});

  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
    userData: {
      [canMarketCreateBPKey]: canMarketCreateABP,
    },
  } = useContext(AppContext);
  const [showAlert, setShowAlert] = useState(false);
  const [isExactSearch, setExactSearch] = useState(false);
  const {
    esoSearchFormFields,
    setEsoSearchFormFields,
    esoSearchColumns,
    setEsoSearchColumns,
    isDunsMarket,
    esoCountryOptions,
    setEsoCountryOptions,
    searchTerm,
    setSearchTerm
  } = useContext(BusinessPartnersContext);
  const { t } = useTranslation();
  const [dunsBusinessPartners, setDunsBusinessPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [canMarketCreateBPViaDuns] = useState(false);
  const [apiError, setApiError] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);

  const getFormFieldsQuery = useESOFormFieldsQuery();
  const searchESOMutation = useSearchESOMutation();
  const createESOMutation = useCreateESOMutation();

  useEffect(() => {
    setIsFormLoading(getFormFieldsQuery.isFetching);
    if (getFormFieldsQuery.isError) {
      setApiError(handleApiError(getFormFieldsQuery.error));
    }
    if (getFormFieldsQuery.isSuccess) {
      const { stringValue, description } = {
        ...getFormFieldsQuery.data?.data?.result || {},
      };

      if (!stringValue && !description) {
        return;
      }
      // when config is turned on...
      // parse json of both string and description and combine both in to a single collection
      let payload = utils.parseJsonString(stringValue);
      let formFields = [...payload.formFields];

      payload = utils.parseJsonString(description);
      let tableHeaders = [{internalName: "action", displayName: t("dunsSearchPage.datagridColumns.action")},...payload.formFields];
      // update state to rehydrate.
      setEsoSearchFormFields(formFields);
      setEsoSearchColumns(tableHeaders);
    }
  }, [getFormFieldsQuery.status]);

  useEffect(() => {
    setIsSearching(searchESOMutation.isPending);
    if (searchESOMutation.isPending) return;
    if (searchESOMutation.isError) {
      setApiError(handleApiError(searchESOMutation.error))
    }
    if (searchESOMutation.isSuccess) {
      if (searchESOMutation.data?.data?.result?.message) {
        let messageJson = utils.parseJsonString(
          searchESOMutation.data?.data?.result?.message,
        );
        if (messageJson?.ErrorCode === 0) {
          setTotalRecords(searchESOMutation.data?.data?.result?.data.length);
          setSearched(true)
          setDunsBusinessPartners(searchESOMutation.data?.data?.result?.data);
        } else {
          let errorDescription =
            messageJson?.ErrorDescription ||
            searchESOMutation.data?.data?.result?.message ||
            t("esoSearchPage.resultMessage");
          createAlert(WARNING, errorDescription);
        }
      } else if (searchESOMutation.data?.data?.result?.data) {
        setTotalRecords(searchESOMutation.data?.data?.result?.data.length);
        setSearched(true);
        setDunsBusinessPartners(searchESOMutation.data?.data?.result?.data);
      }
    }
  }, [searchESOMutation.isPending]);

  useEffect(() => {
    setIsLoading(createESOMutation.isPending);
    if (createESOMutation.isError) {
      setApiError(handleApiError(createESOMutation.error))
    }
    if (createESOMutation.isSuccess) {
      if (createESOMutation.data?.data?.result) {
        handleEsoCreateRecordResponse(createESOMutation.data?.data?.result, createESOMutation.variables);
      } else {
        // If a business partner has been created already from D&B record, displays a warning.
        createAlert(WARNING, createESOMutation.data?.data?.result?.responseMessage);
      }
    }
  }, [createESOMutation.status]);

  useEffect(() => {

    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  const handleChange = (event) => {
    setExactSearch(event.target.checked);
  };

  const sendQuery = async (data) => {
    let paylod = isExactSearch
      ? {
        ...data,
        countryCode: data.countryCode?.value || undefined,
        searchType: "multiple",
      }
      : {
        searchText: searchTerm,
        searchType: "single",
        countryCode:
          esoCountryOptions.length > 0 ? esoCountryOptions[0].value : "",
      };

    searchESOMutation.mutate(paylod);
  };

  useEffect(() => {
    if (searchTerm !== undefined && searchTerm !== null && searchTerm !== '' && esoCountryOptions?.length > 0) {
      sendQuery();
    }
    if(esoCountryOptions?.length > 0) {
      setValue("countryCode", esoCountryOptions[0]);
    }
  }, []);

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  const handleRecordItemSelection = async (item) => {
    // item.type === nkam, open BP using item.uniqueId
    if (item.type === "NKAM") {
      navigate(businessPartnerRoute + item.uniqueId, {});
      return;
    }

    // else => call createeso endpoint
    // select payload from item
    //TODO: make this dynamic using config
    const {
      gcid,
      ucid,
      address1UCID: addressUCID,
      vatNumber,
      nationalId: nationalId1,
      nationalId2,
      nationalId3,
      legalName,
      billingCity: billing_city,
      billingCountry: billing_country,
      billingHouseNo: billing_houseNo,
      billingStreet: billing_street,
      billingPostalCode: billing_postalCode,
      billingAddressGUID: address2ucid,
    } = item;
    const esoCreatePayload = {
      gcid,
      ucid,
      addressUCID,
      vatNumber,
      nationalId1,
      nationalId2,
      nationalId3,
      legalName,
      searchDuns: true,
      countryCode: getValues("countryCode")?.value,
      address2ucid,
      billing_houseNo,
      billing_street,
      billing_city,
      billing_postalCode,
      billing_country,
      esoItem: item,
    };
    createESOMutation.mutate(esoCreatePayload);
  };

  const handleEsoCreateRecordResponse = (response, payload) => {
    let route = "";
    let propData = {};
    // sucess == false && dunssearch == false
    if (!response?.success && !response?.searchDuns) {
      // simply show duns page with out prepopulation
      createAlert(WARNING, t("esoSearchPage.createRecordError"));
      return;
    }

    // sucess == false && dunssearch == true
    // open esoduns page with type = lookup
    else if (!response?.success && response?.searchDuns) {
      propData = {
        preFillFormData: getValues(),
        gcid: payload?.gcid,
        esoRecord: payload,
        searchType: DunsSearchType.LOOKUP,
      };
      route = esodunsSearchRoute;
    }

    // sucess == true && dunssearch == false
    // open just created businesspartner
    else if (response?.success && !response?.searchDuns) {
      route = `${businessPartnerRoute}${response?.createdId}`;
    }

    // sucess == true && dunssearch == true
    // open esoduns page with type = duns
    else if (response?.success && response?.searchDuns) {
      propData = {
        preFillFormData: getValues(),
        gcid: payload?.gcid,
        esoRecord: payload,
        searchType: DunsSearchType.DUNS,
        dunsResponse: response?.dunsResponse,
      };
      route = esodunsSearchRoute;
    }

    // finally navigate
    navigate(route, {
      state: { ...propData },
    });
  };

  return (
    <>
      <ActionsBar />

      <Box mt={3}>
        <StyledHeading component="h4" variant="h4">
          {`2. ${t("esoSearchPage.heading")}`}
        </StyledHeading>
        <StyledSubHeading component="h5" variant="h5">
          {`${t("businessPartnersPage.step")} 2. ${t("esoSearchPage.subHeading")}`}
        </StyledSubHeading>

        <StyledPageDescription component="p" variant="body2">
          {t("esoSearchPage.pageDesc")}
        </StyledPageDescription>
      </Box>

      <Box component="form" id="searchForm" onSubmit={handleSubmit(sendQuery)}>
        <Grid
          container
          spacing={1}
          className="searchFormRow"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <div className="col-md">
              <TextField
                label={t("esoSearchPage.searchTextLabel")}
                onChange={(event) => {
                  setSearchTerm(event.target.value)
                }}
                type="search"
                disabled={isExactSearch}
                value={searchTerm ? searchTerm : undefined}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <StyledDescription component="p" variant="body2" textAlign="center">
              {t("esoSearchPage.styleDescription")}
            </StyledDescription>
            <StyledOrBox
              display="flex"
              width={150}
              justifyContent="center"
              margin={1}
              borderRight="1px solid #8a8c8f"
              borderLeft="1px solid #8a8c8f"
              borderRadius={0}
            >
              <Switch
                label={t("esoSearchPage.exactSearchLabel")}
                checked={isExactSearch}
                onChange={handleChange}
              />
            </StyledOrBox>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            className="col-md align-self-center"
          >
            <Grid container spacing={2}>
              {!isFormLoading ? (
                esoSearchFormFields.map((field) => {
                  if (field?.internalName === "countryCode") {
                    return (
                      <Grid
                        key={field?.internalName}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                      >
                        <CustomControl
                          control={control}
                          disabled={
                            !isExactSearch || esoCountryOptions.length === 1
                          }
                          label={field?.displayName}
                          options={esoCountryOptions}
                          mode={EDIT}
                          name={field?.internalName}
                          type={LOOKUP}
                          disableClearable={true}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid
                        key={field?.internalName}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                      >
                        <CustomControl
                          control={control}
                          disabled={!isExactSearch}
                          label={field?.displayName}
                          mode={EDIT}
                          name={field?.internalName}
                          type={TEXT}
                        />
                      </Grid>
                    );
                  }
                })
              ) : (
                <>
                  <Grid item xs={4}>
                    <Skeleton variant="rectangular" />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton variant="rectangular" />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton variant="rectangular" />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton variant="rectangular" />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton variant="rectangular" />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton variant="rectangular" />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <StyledOverrideMUIFabPositioningButton
                type="submit"
                aria-label={t("esoSearchPage.search")}
              >
                <SearchIcon /> {t("esoSearchPage.search")}
              </StyledOverrideMUIFabPositioningButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={1} display="flex" flexDirection={"column"} gap={2}>
        {(searched || isLoading || dunsBusinessPartners?.length > 0) && (
          <StyledTitle component="h2" variant="h5">
            {`${t("esoSearchPage.searchResults")} - ${t("searchBPCommon.searchResultselperMessage")}`}
          </StyledTitle>
        )}

        {dunsBusinessPartners.length > 0 && (
          <Typography
            variant="body2"
            style={{ lineHeight: "1.5", textAlign: "right" }}
            m="auto"
          >
            {t("esoSearchPage.showing")} {totalRecords}{" "}
            {t("esoSearchPage.results")}
          </Typography>
        )}
      </Box>

      <StyledDivider />

      <StyledResultsContainer>
        {isSearching && <Loader />}

        {dunsBusinessPartners.length === 0 ? (
          <>
            {searched && (
              <>
                <Box mb={3}>
                  <BPNoResults message={t("searchBPNoResults.esoMessage")} />
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <ResultsList
              header={<TableHeader data={esoSearchColumns} />}
              data={dunsBusinessPartners}
              createEsoRecord={handleRecordItemSelection}
              canMarketCreateBP={canMarketCreateABP}
              canMarketCreateBPViaDuns={canMarketCreateBPViaDuns}
            />
          </>
        )}
      </StyledResultsContainer>

      {isLoading && <Backdrop />}
      {searched && (
        <>
        <MultiElementFloatingActionBar>
          {isDunsMarket && dunsBusinessPartners.length === 0 && (
            <StyledPageFab
              aria-label={t("searchCTAButtons.searchDUNS")}
              component={Link}
              to={esodunsSearchRoute}
              state={{
                preFillFormData: getValues(),
              }}
            >
              <AddIcon /> {t("searchCTAButtons.searchDUNS")}
            </StyledPageFab>
          )}
        </MultiElementFloatingActionBar>
          {!(isDunsMarket && dunsBusinessPartners.length == 0) && (
            <Box mt={2} display="flex" justifyContent="center">
            <StyledSecondaryPageFab
              aria-label={t("searchCTAButtons.createBP")}
              component={Link}
              disabled={!canMarketCreateABP}
              to={createBusinessPartnerRoute}
            >
              <AddIcon /> {t("searchCTAButtons.createBP")}
            </StyledSecondaryPageFab>
            </Box>
          )}
        </>
      )}

      {showAlert && alertStatus && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
// added theme to be sure
const StyledResultsContainer = styled(Box)(() => ({
  position: "relative",
  minHeight: 150,
}));
// added theme to be sure
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));
const StyledDescription = styled(Typography)(({ theme }) => ({
  color: "#8a8c8f",
  marginTop: theme.spacing(0.5),
}));
//fixed bad HOC
const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: "#9da0a0",
  margin: theme.spacing(3, 0, 3, 0),
}));
const StyledOverrideMUIFabPositioningButton = styled(Button)({
  right: "auto",
  position: "relative",
});
