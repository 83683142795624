import React from "react";
import ESOCreateIcon from "@/assets/images/icons/ESO-Icon.svg?react";
import NKAMViewIcon from "@/assets/images/icons/NKAM-Icon.svg?react";
import Box from "@mui/material/Box";
import StyledTableCell from "@/components/BusinessPartner/StyledTableCell";
import StyledTableRow from "@/components/BusinessPartner/StyledTableRow";
import utils from "@/utils/common";
import { StyledDunsButton, StyledTooltipArrow } from "@/components/Styles/PagesComponentsCommon";
import { useTranslation } from "react-i18next";

const DUNSResultsListRow = ({
  item,
  createBusinessPartnerFromDuns,
  canMarketCreateBPViaDuns,
}) => {
  const columnWidth = 260;
  const { t } = useTranslation();
  function StyledTooltip(props) {
    return <StyledTooltipArrow placement="bottom" arrow {...props} />;
  }

  return (
    <StyledTableRow>
      <StyledTableCell scope="row">
        <Box display="flex" alignItems="center">
        <StyledTooltip title={t("dunsSearchPage.createBPTooltip")}>
            <StyledDunsButton
              disabled={!canMarketCreateBPViaDuns}
              onClick={() => createBusinessPartnerFromDuns(item.id)}
            >
              {item.type === "NKAM" ? <NKAMViewIcon /> : <ESOCreateIcon />}
            </StyledDunsButton>
          </StyledTooltip>
        </Box>
      </StyledTableCell>
      <StyledTableCell scope="row" width={columnWidth}>
        <Box ml={1}>{utils.trimAndEllipsis(item.name, 50)}</Box>
      </StyledTableCell>
      <StyledTableCell sx={{ color: (theme) => item.type === "NKAM" ? theme.palette.primary.main : theme.palette.common.orange}}>
        {utils.trimAndEllipsis(item.type, 10)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.street1, 15)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.street2, 25)}
      </StyledTableCell>
      <StyledTableCell>{utils.trimAndEllipsis(item.city, 7)}</StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.county, 25)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.postCode, 15)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.vatNumber, 10)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.companyNumber, 10)}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default DUNSResultsListRow;
