import { accessTokenKey, idTokenKey } from "@/constants/constants";

const getIdToken = () => {
  const tokenString = sessionStorage.getItem(idTokenKey);
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const getAccessToken = () => {
    const accessToken = sessionStorage.getItem(accessTokenKey);
  return accessToken;
};

const saveIdToken = (userToken) => {
  sessionStorage.setItem(idTokenKey, JSON.stringify(userToken));
};

const saveAccessToken = (accessToken) => {
  sessionStorage.setItem(accessTokenKey, accessToken);
};

const useToken = {
  getIdToken,
  saveIdToken,
  getAccessToken,
  saveAccessToken,
};

export default useToken;
