import React from 'react';
import ESOCreateIcon from "@/assets/images/icons/ESO-Icon.svg?react";
import NKAMViewIcon from "@/assets/images/icons/NKAM-Icon.svg?react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
import StyledTableCell from "@/components/BusinessPartner/StyledTableCell";
import StyledTableRow from "@/components/BusinessPartner/StyledTableRow";
import utils from "@/utils/common";
import { useTranslation } from "react-i18next";
export const EsoDunsResultsListRow = ({ uniqueKey, item, createEsoRecord }) => {
  const columnWidth = 260;

  const { t } = useTranslation();
  function StyledTooltip(props) {
    return <StyledArrow placement="bottom" arrow {...props} />;
  }

  return (
    <StyledTableRow key={uniqueKey}>
      <StyledTableCell scope="row" width={30}>
        <Box display="flex" alignItems="center">
          <StyledTooltip title={t("formActions.create")}>
            <StyledButton
              onClick={() => createEsoRecord(item)}
            >
              {item.type === "NKAM" ? <NKAMViewIcon /> : <ESOCreateIcon />}
            </StyledButton>
          </StyledTooltip>
        </Box>
      </StyledTableCell>
      <StyledTableCell scope="row" width={columnWidth}>
        <Box ml={1}>{utils.trimAndEllipsis(item?.name, 50)}</Box>
      </StyledTableCell>
      <StyledTableCell sx={{ color: (theme) => item.type === "NKAM" ? theme.palette.primary.main : theme.palette.common.orange}}>
        {utils.trimAndEllipsis(item.type, 10)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.street1, 15)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.street2, 25)}
      </StyledTableCell>
      <StyledTableCell>{utils.trimAndEllipsis(item.city, 7)}</StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.county, 25)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.postCode, 15)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.vatNumber, 10)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.companyNumber, 10)}
      </StyledTableCell>
    </StyledTableRow>
  );
};
const StyledButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  background: "transparent",
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.accent,
    background: "transparent",
  },
}));
const StyledArrow = styled(Tooltip)(({ theme }) => ({
  [`& .arrow`]: {
    color: theme.palette.common.black,
  },
  [`& .tooltip`]: {
    maxWidth: "120px",
    textAlign: "center",
    padding: theme.spacing(1, 0.5, 1, 0.5),
  }
})) 