import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import StyledTableCell from "@/components/BusinessPartner/StyledTableCell";
import StyledTableRow from "@/components/BusinessPartner/StyledTableRow";
import utils from "@/utils/common";
import ESOCreateIcon from "@/assets/images/icons/ESO-Icon.svg?react";
import NKAMViewIcon from "@/assets/images/icons/NKAM-Icon.svg?react";
import { useTranslation } from "react-i18next";
export const ResultsListRow = ({ item, createEsoRecord, uniqueKey }) => {
  const columnWidth = 220;
  const { t } = useTranslation();
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "120px",
      textAlign: "center",
      padding: theme.spacing(1, 0.5, 1, 0.5),
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
  }));

  return (
    <StyledTableRow key={uniqueKey}>
      <StyledTableCell scope="row" >
        <Box display="flex" alignItems="center">
          <StyledTooltip title={item.type === "NKAM" ? t("formActions.view") : t("formActions.create")}>
            <StyledBtn type={item.type}
              onClick={() => createEsoRecord(item)}>
              {item.type === "NKAM" ? <NKAMViewIcon /> : <ESOCreateIcon />}
            </StyledBtn>
          </StyledTooltip>
        </Box>
      </StyledTableCell>
      <StyledTableCell scope="row" width={columnWidth}>
        <Box display="flex" alignItems="center">
          <Box ml={1}>{utils.trimAndEllipsis(item.name, 50)}</Box>
        </Box>
      </StyledTableCell>
      <StyledTableCell sx={{ color: (theme) => item.type === "NKAM" ? theme.palette.primary.main : theme.palette.common.orange}}>
        {utils.trimAndEllipsis(item.type, 10)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.legalName, 10)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.vatNumber, 10)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.street, 15)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.country, 15)}
      </StyledTableCell>
      <StyledTableCell>{utils.trimAndEllipsis(item.city, 7)}</StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.postCode, 25)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.nationalId, 15)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.nationalId2, 15)}
      </StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.nationalId3, 15)}
      </StyledTableCell>
      <StyledTableCell>{item.gcid}</StyledTableCell>
      <StyledTableCell>{item.ucid}</StyledTableCell>
      <StyledTableCell>
        {utils.trimAndEllipsis(item.lastModified, 10)}
      </StyledTableCell>
    </StyledTableRow>
  );
};
const StyledBtn = styled(IconButton)(({ theme }) => ({
  padding: 0,
  background: "transparent",
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.accent,
    background: "transparent",
  },
}));
