import env from "@beam-australia/react-env";

import {
  accessTokenKey,
  canMarketCreateBPKey,
  canMarketCreateContactKey,
  canMarketCreateContractKey,
  canMarketEditBPKey,
  canMarketEditContactKey,
  canMarketEditContractKey,
  idTokenExpiryKey,
  idTokenRealmKey,
  idTokenKey,
  userNameKey,
  marketCodeKey,
  userQnumberKey,
  masterRetailerNumberKey,
  dealerNameKey,
  businessPartnerIdKey
} from "@/constants/constants";

import secureLocalStorage from "react-secure-storage";
import {
  BAD_REQUEST_400,
  BAD_REQUEST_400_TITLE,
  FORBIDDEN_403,
  FORBIDDEN_403_TITLE,
  INTERNAL_SERVER_ERROR_500,
  INTERNAL_SERVER_ERROR_500_TITLE,
  NOT_FOUND_404,
  NOT_FOUND_404_TITLE,
  UNAUTHORISED_401,
  UNAUTHORISED_401_TITLE,
} from "@/constants/errors";

let bmwInternetDomain = env("BMW_INTERNET_DOMAIN");
let bmwIntranetDomain = env("BMW_INTRANET_DOMAIN");
let sgateB2DAuthDomain = env("SGATE_B2D_AUTH_DOMAIN")
let sgateB2DAuthService = env("SGATE_B2D_AUTH_SERVICE")
let sgateB2DClientId = env("SGATE_B2D_CLIENT_ID");
let sgateB2DRealm = env("SGATE_B2D_REALM");
let sgateB2EAuthDomain = env("SGATE_B2E_AUTH_DOMAIN")
let sgateB2EAuthService = env("SGATE_B2E_AUTH_SERVICE")
let sgateB2EClientId = env("SGATE_B2E_CLIENT_ID");
let sgateB2ERealm = env("SGATE_B2E_REALM");
let sgateFallbackRealm = env("SGATE_FALLBACK_REALM");
let sgateLoginUrlTemplate = env("SGATE_LOGIN_URL_TEMPLATE");
let sgateLogoutUrlTemplate = env("SGATE_LOGOUT_URL_TEMPLATE");


const isTokenValid = () => {
  if (!localStorage.getItem(idTokenExpiryKey)) return false;
  if (!sessionStorage.getItem(idTokenRealmKey)) return false;

  const now = new Date().getTime();
  const originalHost = getOriginalHost();
  const realm = getRealm(originalHost);
  const tokenExpiry = localStorage.getItem(idTokenExpiryKey) * 1000;
  const tokenRealm = sessionStorage.getItem(idTokenRealmKey);

  if (now > tokenExpiry) {
    return false;
  }

  if (realm !== tokenRealm) {
    return false;
  }

  return true;
};

const getRealm = (originalHost) => {
  let sgateRealm;

  if (originalHost.toLowerCase().includes(`.${bmwInternetDomain}`)) {
    sgateRealm = sgateB2DRealm;
  }
  else if (originalHost.toLowerCase().includes(`.${bmwIntranetDomain}`)) {
    sgateRealm = sgateB2ERealm;
  }
  else {
    sgateRealm = sgateFallbackRealm;
  }

  return sgateRealm;
};

const getOriginalHost = () => {
  var fullHost;

  let builder = [];

  if (window.location?.protocol != null) {
    builder.push(`${window.location.protocol}//`);
  }

  builder.push(window.location.hostname);

  if (window.location?.port != null && window.location.port !== "" && window.location.port !== "443") {
    builder.push(`:${window.location.port}`);
  }

  fullHost = builder.join("");

  return fullHost;
}

const trimAndEllipsis = (value, length) => {
  if (value) {
    let trimmed =
      value.length > length ? `${value.substring(0, length)}...` : value;

    return trimmed;
  } else {
    return value;
  }
};

const formatString = (s, ...args) => {
  var a = args;
  return s.replace(/(\{\{\d\}\}|\{\d\})/g, function (r) {
    if (r.substring(0, 2) === "{{") return r;
    var index = parseInt(r.match(/\d/)[0]);
    return a[index]
  })
};

const autoWildcardSearchTerm = (stringIn) => {
  if (stringIn.length > 0) {
    if (stringIn.includes("*")) {
      return stringIn;
    } else {
      return "*" + stringIn + "*";
    }
  } else {
    return "";
  }
};

const generateRandomString = (length = 6) => {
  let start = 2;
  let end = start + length;
  return Math.random().toString(20).substring(start, end);
};

function saveToLocalStorage(key, value) {
  window.localStorage.setItem(key, value);
}

function secureSaveToLocalStorage(key, value) {
  secureLocalStorage.setItem(key, value);
}

function saveToSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
}

function getFromLocalStorage(key) {
  let value = window.localStorage.getItem(key);
  return value;
}

function secureGetFromLocalStorage(key) {
  let value = secureLocalStorage.getItem(key);
  return value;
}

function getFromSessionStorage(key) {
    let value = sessionStorage.getItem(key);
    return value;
}

function clearFromLocalStorage(key) {
  window.localStorage.removeItem(key);
}

function secureClearFromLocalStorage(key) {
  secureLocalStorage.removeItem(key);
}

function clearFromSessionStorage(key) {
  sessionStorage.removeItem(key);
}

const redirectToSGateForLogin = async () => {
  const originalHost = getOriginalHost();
  const sgateRealm = getRealm(originalHost);

  clearAllTokensFromStorage();

  // Prepare the base url and query fragments for the redirect to S-Gate
  let nonce = generateRandomString(8);
  let scope = encodeURIComponent('openid profile b2d b2xroles organization');
  let responseType = encodeURIComponent('id_token token');
  let queryFragments = `state=${sgateRealm}&scope=${scope}&redirect_uri=${originalHost}&response_type=${responseType}&nonce=${nonce}`;

  if (sgateRealm === sgateB2ERealm) {
    if (sgateB2EAuthService != null) {
      queryFragments = `${queryFragments}&acr_values=${sgateB2EAuthService}`
    }
    let sgateLoginUrl = formatString(sgateLoginUrlTemplate, sgateB2EAuthDomain, sgateRealm)
    window.location.href = `${sgateLoginUrl}?client_id=${sgateB2EClientId}&${queryFragments}`;
  } else {
    if (sgateB2DAuthService != null) {
      queryFragments = `${queryFragments}&acr_values=${sgateB2DAuthService}`
    }
    let sgateLoginUrl = formatString(sgateLoginUrlTemplate, sgateB2DAuthDomain, sgateRealm)
    window.location.href = `${sgateLoginUrl}?client_id=${sgateB2DClientId}&${queryFragments}`;
  }
};

const clearAllTokensFromStorage = () => {
  clearFromSessionStorage(accessTokenKey);
  clearFromSessionStorage(idTokenKey);
  clearFromLocalStorage(idTokenExpiryKey);
  clearFromSessionStorage(idTokenRealmKey);
  clearFromLocalStorage(canMarketCreateBPKey);
  clearFromLocalStorage(canMarketEditBPKey);
  clearFromLocalStorage(canMarketCreateContractKey);
  clearFromLocalStorage(canMarketEditContractKey);
  clearFromLocalStorage(canMarketCreateContactKey);
  clearFromLocalStorage(canMarketEditContactKey);
  clearFromLocalStorage(marketCodeKey);
  clearFromLocalStorage(userQnumberKey);
  clearFromLocalStorage(masterRetailerNumberKey);
  clearFromLocalStorage(dealerNameKey);
  clearFromLocalStorage(businessPartnerIdKey);
  secureClearFromLocalStorage(userNameKey);
};

const redirectToSGateForLogout = async (idtoken) => {
  const originalHost = getOriginalHost();
  const sgateRealm = getRealm(originalHost);

  clearAllTokensFromStorage();

  // Prepare the base url and query fragments for the redirect to S-Gate
  let queryFragments = `id_token_hint=${idtoken}&post_logout_redirect_uri=${originalHost}`;

  if (sgateRealm === sgateB2ERealm) {
    let sgateLogoutUrl = formatString(sgateLogoutUrlTemplate, sgateB2EAuthDomain, sgateRealm)
    window.location.href = `${sgateLogoutUrl}?${queryFragments}`;
  } else {
    let sgateLogoutUrl = formatString(sgateLogoutUrlTemplate, sgateB2DAuthDomain, sgateRealm)
    window.location.href = `${sgateLogoutUrl}?${queryFragments}`;
  }
};

const parseJsonString = (jsonString) => {
  if (typeof jsonString !== "string") {
    return {};
  }

  try {
    let jsonObj = JSON.parse(jsonString);
    return jsonObj;
  } catch (error) {
    return {};
  }
};

const statusCodeTextLookUp = (status) => {
  let errorTitle;
  if (status === BAD_REQUEST_400) {
    errorTitle = BAD_REQUEST_400_TITLE;
  } else if (status === UNAUTHORISED_401) {
    errorTitle = UNAUTHORISED_401_TITLE;
  } else if (status === FORBIDDEN_403) {
    errorTitle = FORBIDDEN_403_TITLE;
  } else if (status === NOT_FOUND_404) {
    errorTitle = NOT_FOUND_404_TITLE;
  } else if (status === INTERNAL_SERVER_ERROR_500) {
    errorTitle = INTERNAL_SERVER_ERROR_500_TITLE;
  }
  return errorTitle;
};

const utils = {
  trimAndEllipsis,
  formatString,
  autoWildcardSearchTerm,
  saveToLocalStorage,
  secureSaveToLocalStorage,
  saveToSessionStorage,
  getFromLocalStorage,
  secureGetFromLocalStorage,
  getFromSessionStorage,
  clearFromLocalStorage,
  secureClearFromLocalStorage,
  clearFromSessionStorage,
  redirectToSGateForLogin,
  redirectToSGateForLogout,
  isTokenValid,
  parseJsonString,
  statusCodeTextLookUp
};

export default utils;
